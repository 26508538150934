import styled from '@emotion/styled';
import 'react-multi-carousel/lib/styles.css';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const ContinueLearningCardStyle = styled.div`
  width: 272px;
  position: relative;
  .img {
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    width: 272px;
    height: 160.72px;
    margin-bottom: 0.5rem;
  }
  .img-gradient {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 45.83%);
    position: absolute;
    width: 272px;
    height: 160.72px;
    left: 0px;
    top: 0px;

    &:hover ~ .icon-play .wrapper {
      opacity: 1;
    }
  }
  .icon-play {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
  }
  .course-name {
    position: absolute;
    bottom: 18px;
    left: 15px;
    color: white;
    font-size: 18px;
    font-family: 'Heavent';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 242px;

    &:hover ~ .icon-play .wrapper {
      opacity: 1;
    }
  }
`;

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#cccccc',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
  },
}));

export default ContinueLearningCardStyle;
