import React from 'react';

import { Box, BoxProps } from '@mui/material';
import { noop } from 'lodash';

import { IconSize } from './constants';
import IconBuilder from './iconBuilder';
import Style from './style';

interface FontAwesomeIconProp {
  spin?: boolean;
  fixedWidth?: boolean;
  sharp?: boolean;
  size?: IconSize;
  style?: React.CSSProperties;
  width?: string | number;
  color?: string;
  sx?: BoxProps['sx'];
  className?: string;
  icon: string;
  onClick?: typeof noop;
}

/**
 * FontAwesomeIcon Component
 * @param {FontAwesomeIconProp} props
 * @returns {React.ReactElement} FontAwesomeIcon
 *
 * @example
 * <FontAwesomeIcon style={{ fontSize: '40px' }} icon="fa-duotone fa-rotate-right" />
 * <FontAwesomeIcon style={{ fontSize: '60px' }} icon="fa-light fa-rotate-right" />
 */
const FontAwesomeIcon: React.FC<FontAwesomeIconProp> = ({ sx = {}, ...props }) => {
  const iconBuilder = new IconBuilder(props);
  iconBuilder.setFixWidth();
  iconBuilder.setSpin();
  iconBuilder.setSharp();
  iconBuilder.setSize();
  const className = iconBuilder.getClassName();
  const style = iconBuilder.getStyle();
  const { onClick } = props;

  return (
    <Box sx={{ display: 'inherit', ...sx }}>
      <Style className={className} style={style} onClick={onClick} />
    </Box>
  );
};

export default FontAwesomeIcon;
