import Router from 'next/router';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import enrollmentAPI, { ENROLLMENT_END_POINT } from 'src/api/enrollment';
import ContinueLearningCarousel from 'src/components/molecules/continueLearning/carousel';
import { useProfile, useRequest } from 'src/helpers/use';
import { IEnrollment } from 'src/interfaces';

import ContinueLearningSectionStyle from './style';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import { ROUTES } from '@helpers/constants';

const ContinueLearningSection = () => {
  const { t } = useTranslation('common');

  const { profile } = useProfile();
  const { data: enrollments } = useRequest<IEnrollment[]>(
    profile ? ENROLLMENT_END_POINT.ENROLLMENTS_ME : null,
    enrollmentAPI.fetchCoursesEnrollment,
  );

  const continueLearningEnrollment = enrollments
    ?.filter(
      (enrollment) =>
        enrollment.finished_course_items_amount < enrollment.course_items_amount && !enrollment.is_expired,
    )
    ?.slice(0, 6);

  return (
    <>
      {continueLearningEnrollment && continueLearningEnrollment.length > 0 && (
        <ContinueLearningSectionStyle>
          <Box sx={{ my: 1.5, mx: 1 }} display="flex" alignItems="baseline" justifyContent="space-between">
            <Typography className="title" sx={{ maxWidth: '60%', lineHeight: 1 }} variant="h1">
              {t('home.continue_learning')}
            </Typography>
            <Button variant="text" className="button-link" onClick={() => Router.push(ROUTES.MY_COURSE)}>
              {t('my_course')} <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
            </Button>
          </Box>
          <ContinueLearningCarousel enrollments={continueLearningEnrollment} />
        </ContinueLearningSectionStyle>
      )}
    </>
  );
};

export default ContinueLearningSection;
