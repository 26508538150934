import { withTheme } from '@mui/styles';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';

const ContinueLearningSectionStyle = withTheme(styled.div`
  padding: 0 1rem;
  position: relative;
  .title {
    flex-grow: 2;
  }
  .icon-play {
    svg {
      font-size: 44px;
    }
  }
  .carousel-item {
    padding: 0 0.5rem 1rem 0.5rem;
    flex: 0 0 !important;
  }

  .button-link {
    color: ${(props: any) => props.theme.palette.primary.main};
    font-family: 'Heavent';
    font-size: 18px;
    padding: 0;
    font-weight: bold;
    i {
      margin-left: 8px;
      font-size: 16px;
      display: flex;
    }
  }
  .react-multi-carousel-list {
    position: unset;
  }
  @media (min-width: 2000px) {
    max-width: 1762px;
    margin: 4rem auto;
  }
  @media (max-width: 1999px) and (min-width: 1701px) {
    max-width: 1472px;
    margin: 4rem auto;
  }
  @media (max-width: 1700px) and (min-width: 1200px) {
    max-width: 1182px;
    margin: 4rem auto;
  }
  @media (max-width: 1199px) {
    margin: 4rem 0;
  }
  @media (max-width: 767px) {
    margin: 3rem 0.5rem;
    padding: 0px;
    .title {
      font-size: 24px;
    }
  }
`);

export default ContinueLearningSectionStyle;
