import styled from '@emotion/styled';

const IconCircleButtonStyle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  .wrapper {
    position: absolute;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    z-index: 1;
    background-color: white;
    opacity: 0.75;
  }
  .wrapper:hover {
    opacity: 1;
  }
  button {
    min-width: 60px;
    min-height: 60px;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    z-index: 2;
    color: ${(props: any) => props.theme.palette.primary.dark};
  }
`;

export default IconCircleButtonStyle;
