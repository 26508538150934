import React from 'react';

import { IconSize } from '../constants';

interface IconBuilderProps {
  className?: string;
  icon: string;
  spin?: boolean;
  fixedWidth?: boolean;
  sharp?: boolean;
  size?: IconSize;
  style?: React.CSSProperties;
  width?: string | number;
  color?: string;
}

class IconBuilder {
  private className: string;

  private spin: boolean;

  private fixedWidth: boolean;

  private sharp: boolean;

  private size?: IconSize;

  private style: React.CSSProperties;

  private width?: string | number;

  private color?: string;

  constructor(params: IconBuilderProps) {
    this.className = `${params?.className || ''} ${params.icon}`;
    this.spin = params?.spin ?? false;
    this.fixedWidth = params?.fixedWidth || false;
    this.sharp = params?.sharp ?? false;
    this.size = params?.size;
    this.style = params?.style || {};
    this.width = params?.width;
    this.color = params?.color;

    this.setFixWidth();
    this.setSpin();
    this.setSharp();
    this.setSize();
  }

  public setFixWidth() {
    if (!this.fixedWidth) return;
    this.className += ' fa-fw';
  }

  public setSpin() {
    if (!this.spin) return;
    this.className = `fas ${this.className} fa-spin`;
  }

  public setSharp() {
    if (!this.sharp) return;
    this.className = `fa-sharp ${this.className}`;
  }

  public setSize() {
    if (!this.size) return;
    this.className = `${this.className} ${this.size || ''}`;
  }

  public getClassName() {
    return this.className;
  }

  public getStyle(): React.CSSProperties {
    const style: React.CSSProperties = {};

    if (this.color) {
      style.color = this.color;
    }

    if (this.width) {
      style.fontSize = this.width;
    }

    return {
      ...style,
      ...this.style,
    };
  }
}

export default IconBuilder;
