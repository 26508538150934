import { Box } from '@mui/material';
import SvgIcon from 'material-ui/SvgIcon';

import IconCircleButtonStyle from './style';

import Button from 'src/components/atoms/button';

type IconCircleButtonProps = {
  icon: React.ReactElement;
};

const IconCircleButton = ({ icon }: IconCircleButtonProps) => (
  <IconCircleButtonStyle>
    <Box className="wrapper">
      <Button>{icon}</Button>
    </Box>
  </IconCircleButtonStyle>
);

export default IconCircleButton;
