import { memo } from 'react';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, CardActionArea } from '@mui/material';

import ContinueLearningCardStyle, { BorderLinearProgress } from './style';

import IconCircleButton from 'src/components/atoms/iconCircleButton';
import { NextImage } from 'src/components/atoms/image';
import NextLink from 'src/components/atoms/link';
import config from 'src/config';
import { ICourse } from 'src/interfaces';

export type Props = {
  course: ICourse;
  totalAmount: number;
  finishedAmount: number;
};

export type CourseImageProps = {
  course: ICourse;
  link: string;
};

export type ProgressLearningProps = {
  totalAmount: number;
  finishedAmount: number;
};

const CourseImage = ({ course, link }: CourseImageProps) => (
  <>
    <NextLink href={link}>
      <CardActionArea>
        <Box sx={{ position: 'relative', width: '272px', height: '160.72px', mb: '0.5rem' }}>
          <NextImage
            className="img"
            alt={course.name}
            src={course.highlight_medium_image}
            errorImage={`${config.IMAGE_CDN_HOST}/assets/thumbnail-course-card.jpg`}
            layout="fill"
          />
        </Box>
        <Box className="img-gradient" />
        <Box className="course-name">{course.name}</Box>
        <Box className="icon-play">
          <IconCircleButton icon={<PlayArrowRoundedIcon />} />
        </Box>
      </CardActionArea>
    </NextLink>
  </>
);
const MemoizedCourseImage = memo(CourseImage);

const ProgressLearning = ({ totalAmount = 5, finishedAmount = 0 }: ProgressLearningProps) => {
  const value = finishedAmount === 0 ? 0 : (finishedAmount / totalAmount) * 100;
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <BorderLinearProgress variant="determinate" value={value} />
      </Box>
    </>
  );
};

const MemoizedProgressLearning = memo(ProgressLearning);

const ContinueLearningCard = ({ course, totalAmount = 5, finishedAmount = 0 }: Props) => {
  const link = (() => `courses/${course.id_name}/chapter`)();
  return (
    <ContinueLearningCardStyle>
      <MemoizedCourseImage course={course} link={link} />
      <MemoizedProgressLearning totalAmount={totalAmount} finishedAmount={finishedAmount} />
    </ContinueLearningCardStyle>
  );
};

export default ContinueLearningCard;
